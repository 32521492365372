import React from 'react'

const Dashboard = () => {
  return (
    <div className="section-inner vertical-indent--s">
      <h1 className="regular">Здесь будут Ваши курсы</h1>
    </div>
  )
}

export default Dashboard
