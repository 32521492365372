import React from 'react'
import { navigate } from 'gatsby'
import { useApp } from '../context/appContext'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated } = useApp()

  if (!isAuthenticated()) {
    // hack to avoid "window" is not available during server side rendering error
    if (typeof window !== `undefined`) {
      navigate('/login')
    }
    return null
  }
  return <Component {...rest} />
}

export default PrivateRoute
