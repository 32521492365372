import React from 'react'
import { Link } from 'gatsby'

const Profile = () => {
  return (
    <div className="section-inner vertical-indent--s">
      <h1 className="regular">Здесь будет Ваш профиль</h1>
      <Link to="/user/change-password">Поменять пароль</Link>
    </div>
  )
}

export default Profile
