import React from 'react'
import { Router } from '@reach/router'
import { Layout } from '../components'
import { Dashboard, Profile, ChangePassword } from '../components/user'
import PrivateRoute from '../components/PrivateRoute'

const User = () => {
  return (
    <Layout>
      <Router basepath="/user">
        <PrivateRoute path="/dashboard" component={Dashboard} />
        <PrivateRoute path="/profile" component={Profile} />
        <PrivateRoute path="/change-password" component={ChangePassword} />
        <PrivateRoute path="/" component={Dashboard} />
      </Router>
    </Layout>
  )
}

export default User
