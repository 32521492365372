import React from 'react'
import { ChangePassword } from '../authForms'
import '../authForms/authForms.scss'

const Auth = () => {
  return (
    <div className="section-change-password section-inner section-inner--xs vertical-indent--m">
      <h2>Смена пароля</h2>
      <ChangePassword />
    </div>
  )
}

export default Auth
